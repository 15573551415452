import { IconBrandSpeedtest, IconBrush, IconCode, IconFileText, IconSchool, IconSourceCode, IconTrophy, IconUsersGroup, IconWorldWww } from '@tabler/icons-react';

export const stepOne = [
    {
        image: '',
        icon: <IconSchool/>,
        title: 'Estudio de Marca',
        text: 'Iniciamos con una profunda inmersión en tu marca y tus objetivos. Comprendemos tus valores, identidad y visión. Recopilamos todos los requisitos y expectativas para el sitio web.'
    },
    {
        image: '',
        icon: <IconBrush/>,
        title: 'Diseño Creativo',
        text: 'Nuestro equipo creativo trabaja en la concepción visual de tu sitio web. Creamos prototipos que representan cómo se verá y se sentirá el sitio.'
    },
    {
        image: '',
        icon: <IconUsersGroup/>,
        title: 'Desarrollo Continuo',
        text: 'Implementamos el diseño en desarrollo real. Trabajamos en colaboración contigo para asegurarnos de que cada aspecto del sitio se alinee con tus expectativas.'
    }
];

export const stepTwo = [
    {
        image: '',
        icon: <IconFileText/>,
        title: 'Evaluación de Tecnologías',
        text: 'Comenzamos evaluando las tecnologías y enfoques disponibles. Consideramos factores como la escalabilidad, el rendimiento y los costos.'
    },
    {
        image: '',
        icon: <IconSourceCode/>,
        title: 'Planificación y Estrategias',
        text: 'Creamos un plan detallado que incluye los hitos clave y los recursos necesarios. Definimos roles y responsabilidades y establecemos un cronograma claro para el desarrollo.'
    },
    {
        image: '',
        icon: <IconCode/>,
        title: 'Desarrollo y Pruebas Continuas',
        text: 'Durante el desarrollo, realizamos pruebas para garantizar que todo funcione como se esperaba. Te involucramos en las pruebas de funcionalidad y esperamos tus comentarios.'
    }
];

export const stepThree = [
    {
        image: '',
        icon: <IconBrandSpeedtest/>,
        title: 'Pruebas Exhaustivas',
        text: 'Llevamos a cabo pruebas exhaustivas para verificar la funcionalidad del software. Esto incluye pruebas de unidad, pruebas de integración y pruebas de aceptación.'
    },
    {
        image: '',
        icon: <IconWorldWww/>,
        title: 'Demostración al Cliente',
        text: 'Presentamos el software a tu equipo y solicitamos retroalimentación. Aseguramos que todas las características y funcionalidades cumplan con tus expectativas y requisitos.'
    },
    {
        image: '',
        icon: <IconTrophy/>,
        title: 'Aprobación y Lanzamiento',
        text: 'Una vez que has dado el visto bueno y estás satisfecho con el producto, procedemos al lanzamiento. Garantizamos una transición sin problemas y ofrecemos soporte continuo.'
    }
];