import React from 'react'
import { Link } from 'react-router-dom'

import dluxLogo from '../../../static/img/dlux.png'
import dluxLogoComplete from '../../../static/img/dluxlogo.png'

import './styles/partners.css'

function PartnersSection() {

    return (

        <section className='--partners'>

            <div className='--title'>
                <h1>Socios</h1>
                <p>Los socios son las empresas que están junto a nosotros para cumplir nuestra misión y alcanzar el éxito.</p>
            </div>

            <div className='--ctgs'>
                <ul className='--ctgs-list'>
                    <li className={`--ctgs-item --ctgs-item--active`}>
                        <span>Diseño Gráfico</span>
                    </li>
                    <li className={`--ctgs-item`}>
                        <span>Pronto más</span>
                    </li>
                </ul>
            </div>

            <div className='--grd-partners'>

                <div className='--card-partner'>

                    <div className='--image-bg'>
                        <img src={dluxLogo} alt={`Logo de D'LUX empresa de diseño gráfico digital`} />
                    </div>

                    <div className='--image-fr'>
                        <img src={dluxLogoComplete} alt={`Logo de D'LUX empresa de diseño gráfico digital`} />
                    </div>

                    <Link to={'https://www.facebook.com/dluxdesign12'} target='_blank'>Ver más</Link>

                    <div className='--line'></div>

                </div>

            </div>

        </section>

    )

}

export default PartnersSection