import React from 'react'
import './comment.css'

function CommentSection() {

    const commentarios = [
        {
            nombre: "María García",
            posicion: "Gerente de Marketing",
            comentario: "Increíble servicio. La atención al cliente es impecable y el producto final superó nuestras expectativas. ¡Altamente recomendado!"
        },
        {
            nombre: "Juan Pérez",
            posicion: "Director de Tecnología",
            comentario: "El equipo de desarrollo de software de esta agencia es excepcional. Cumplieron con todos nuestros requisitos y entregaron un producto de alta calidad en tiempo récord."
        },
        {
            nombre: "Laura Martínez",
            posicion: "Jefa de Proyectos",
            comentario: "Estamos encantados con la colaboración. Su capacidad para adaptarse a nuestras necesidades cambiantes fue impresionante. ¡Definitivamente volveremos a trabajar con ellos en el futuro!"
        },
        {
            nombre: "Carlos Rodríguez",
            posicion: "CEO",
            comentario: "Contratar a esta agencia fue una de las mejores decisiones que tomamos para nuestro negocio. Su enfoque personalizado y profesionalismo nos ayudaron a alcanzar nuestros objetivos de desarrollo de software de manera eficiente."
        },
        {
            nombre: "Ana López",
            posicion: "Gerente de Operaciones",
            comentario: "El equipo de esta agencia demostró un alto nivel de experiencia y compromiso en cada etapa del proyecto. Estamos muy agradecidos por su dedicación y resultados sobresalientes."
        },
        {
            nombre: "Diego Sánchez",
            posicion: "Director de Finanzas",
            comentario: "El retorno de inversión que obtuvimos al trabajar con esta agencia fue excepcional. No solo entregaron un producto de calidad, sino que también lo hicieron dentro de nuestro presupuesto. ¡Muy impresionante!"
        }
    ]      

    return (

        <section className='--section-comment'>

            <div className='--titles'>
                <h1>Desafiando los límites</h1>
                <p>Potenciamos innovaciones impactantes para empresas, forjando un camino hacia el éxito, inspirando confianza y acelerando el progreso en el mundo empresarial.</p>
            </div>

            <div className='--slide-comments'>

                <div className='--comments'>

                    {commentarios.map((comment, index) => (

                        <div key={index} className='--comment'>
                            <div>
                                <p className='--comment-txt'>{comment.comentario}</p>
                            </div>
                            <div className='--c-daa'>
                                <div className='--avat'></div>
                                <div className='--dtad'>
                                    <h5>{comment.nombre}</h5>
                                    <span>{comment.posicion}</span>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>

            </div>

        </section>

    )

}

export default CommentSection