export const pricingLst = [
    {
        service: 'Landing Page',
        price: '500',
        subtxt: 'soles',
        items: [
            {
                available: true,
                item: 'Dominio',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Hosting',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Certificado SSL'
            },
            {
                available: true,
                item: 'Correo Corporativo'
            },
            {
                available: true,
                item: 'Diseño Web'
            },
            {
                available: true,
                item: 'Soporte'
            },
            {
                available: false,
                item: 'Mantenimiento'
            },
            {
                available: false,
                item: 'Actualizaciones'
            }
        ],
        link: 'https://wa.link/kv8ffh'
    },
    {
        service: 'Sitio Web',
        price: '1000',
        subtxt: 'soles',
        items: [
            {
                available: true,
                item: 'Dominio',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Hosting',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Certificado SSL'
            },
            {
                available: true,
                item: 'Correo Corporativo'
            },
            {
                available: true,
                item: 'Diseño Web'
            },
            {
                available: true,
                item: 'Soporte'
            },
            {
                available: false,
                item: 'Mantenimiento'
            },
            {
                available: false,
                item: 'Actualizaciones'
            }
        ],
        link: 'https://wa.link/4rckj2'
    },
    {
        service: 'E-commerce',
        price: '2,000',
        subtxt: 'soles',
        items: [
            {
                available: true,
                item: 'Dominio',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Hosting',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Certificado SSL',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Correo Corporativo',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Diseño Web',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Soporte',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Mantenimiento',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Actualizaciones',
                smallTxt: true,
            }
        ],
        link: 'https://wa.link/rku8ua'
    },
    {
        service: 'A medida',
        price: 'Custom',
        subtxt: 'personalizado',
        items: [
            {
                available: true,
                item: 'Dominio',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Hosting',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Certificado SSL',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Correo Corporativo',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Diseño Web',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Soporte',
                smallTxt: false,
            },
            {
                available: true,
                item: 'Mantenimiento',
                smallTxt: true,
            },
            {
                available: true,
                item: 'Actualizaciones',
                smallTxt: true,
            }
        ],
        link: 'https://wa.link/7s9yky'
    }
];