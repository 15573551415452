import React from 'react'
import { Helmet } from "react-helmet";

import PartnersSection from '../components/sections/Parteners/PartnersSection'

function PartnersView() {

    return (

        <>

            <Helmet>
                <meta name="description" content="Descubre nuestros socios estratégicos y colaboradores. Conoce cómo trabajamos en conjunto para ofrecer soluciones tecnológicas innovadoras a nuestros clientes." />
                <meta name="keywords" content="socios, colaboradores, alianzas estratégicas, tecnología, innovación, paquete de identidad visual, diseño y desarrollo web" />
                <meta name="author" content="ARCANA CORP" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Nosotros | ARCANA | Diseño y desarrollo web" />
                <meta property="og:description" content="Descubre nuestros socios estratégicos y colaboradores. Conoce cómo trabajamos en conjunto para ofrecer soluciones tecnológicas innovadoras a nuestros clientes." />
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta property="og:url" content="https://arcanacorp.netlify.app/partners" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Nosotros | ARCANA | Diseño y desarrollo web" />
                <meta name="twitter:description" content="Descubre nuestros socios estratégicos y colaboradores. Conoce cómo trabajamos en conjunto para ofrecer soluciones tecnológicas innovadoras a nuestros clientes." />
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <title>Socios | ARCANA | Diseño y desarrollo web</title>
            </Helmet>

        
            <PartnersSection/>

        </>

    )

}

export default PartnersView