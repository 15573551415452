import React from 'react'
import BlogSection from '../components/sections/Blog/BlogSection'

function BlogView() {

    return (

        <>
        
            <BlogSection/>

        </>

    )

}

export default BlogView