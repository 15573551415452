import React from 'react'

import './styles/product.css'
import ProductCard from '../../ui/card/ProductCard'

function ProductSection() {

    return (

        <div className='--products'>

            <div className='--title'>
                <h1>Productos</h1>
                <p>Aquí puedes encontrar una gama de nuestros productos que crearan la suite de productividad para todos.</p>
            </div>

            <div className='--grd-products'>
                <ProductCard/>
                <ProductCard/>
                <ProductCard/>
                <ProductCard/>
                <ProductCard/>
                <ProductCard/>
            </div>

        </div>

    )
    
}

export default ProductSection