import React from 'react'
import { Helmet } from 'react-helmet'

import AboutSection from '../components/sections/About/AboutSection'

function AboutView() {

    return (

        <>

            <Helmet>
                <meta name="description" content="Conoce más sobre ARCANA, nuestra misión, visión y valores. Descubre cómo nuestro equipo de expertos en tecnología trabaja para ofrecer soluciones innovadoras a nuestros clientes." />
                <meta name="keywords" content="nosotros, misión, visión, valores, equipo, tecnología, innovación, arcana, arcana corp, diseño y desarrollo web, desarrollo, web, corp, developers" />
                <meta name="author" content="ARCANA CORP" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Nosotros | ARCANA | Diseño y desarrollo web" />
                <meta property="og:description" content="Conoce más sobre ARCANA, nuestra misión, visión y valores. Descubre cómo nuestro equipo de expertos en tecnología trabaja para ofrecer soluciones innovadoras a nuestros clientes." />
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta property="og:url" content="https://arcanacorp.netlify.app/about" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Nosotros | ARCANA | Diseño y desarrollo web" />
                <meta name="twitter:description" content="Conoce más sobre ARCANA, nuestra misión, visión y valores. Descubre cómo nuestro equipo de expertos en tecnología trabaja para ofrecer soluciones innovadoras a nuestros clientes." />
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <title>Nosotros | ARCANA | Diseño y desarrollo web</title>
            </Helmet>

        
            <AboutSection/>

        </>

    )

}

export default AboutView