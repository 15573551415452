import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconChevronRight, IconMenu } from '@tabler/icons-react'

import UIContext from '../context/UIContext'

import logo from '../static/img/LOGOTIPO-WHITE.svg'

import './styles/header.css'

function Header() {

    const { isScrollingDown } = useContext(UIContext);

    const [ toogleMenu, setToogleMenu ] = useState(false);

    const handleToogleMenu = () => {
        setToogleMenu(!toogleMenu);
        document.body.classList.toggle('--isnt-scrolling-down')
    }

    const location = useLocation();

    return (

        <header className={`--header ${isScrollingDown ? '--header-glass' : ''}`}>

            <div className='--content-header'>

                <div className='--box'>

                    <div className='--col --col-A'>
                        <div className='--logo'>
                            <Link to={'/'} className='--a-logo'>
                                <img src={logo} alt={`Logo de A.R.C.A.N.A - Desarrollo Web y Tecnológico PERÚ | HUANCAYO | DISEÑO Y DESARROLLO DE PÁGINAS WEB Y APLICACIONES A MEDIDA`} />
                            </Link>
                        </div>
                    </div>

                    <div className='--col --col-B'>

                        <ul className='--ul-nav'>
                            <li className='--li-nav'>
                                <Link to={'/products'} className={`--a-nav ${location.pathname === '/products' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Productos</span>
                                </Link>
                            </li>
                            <li className='--li-nav'>
                                <Link to={'/proyects'} className={`--a-nav ${location.pathname === '/proyects' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Proyectos</span>
                                </Link>
                            </li>
                            <li className='--li-nav'>
                                <Link to={'/pricing'} className={`--a-nav ${location.pathname === '/pricing' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Precios</span>
                                </Link>
                            </li>
                            <li className='--li-nav'>
                                <Link to={'/partners'} className={`--a-nav ${location.pathname === '/partners' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Socios</span>
                                </Link>
                            </li>
                            <li className='--li-nav'>
                                <Link to={'/blog'} className={`--a-nav ${location.pathname === '/blog' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Blog</span>
                                </Link>
                            </li>
                            <li className='--li-nav'>
                                <Link to={'/about'} className={`--a-nav ${location.pathname === '/about' ? '--a-nav--active' : ''}`}>
                                    <span className='--span-nav'>Nosotros</span>
                                </Link>
                            </li>
                        </ul>

                    </div>

                    <div className='--col --col-C'>

                        <ul className='--ul-options'>
                            <li className='--li-options'>
                                <Link to={'https://wa.link/3casfa'} target='_blank' className='--a-options'>
                                    <span className='--span-options'>Contáctanos</span>
                                    <IconChevronRight/>
                                </Link>
                            </li>
                            <li className='--li-options'>
                                <button className='--a-options-btn' onClick={handleToogleMenu}><IconMenu/></button>
                            </li>
                        </ul>

                    </div>

                </div>

                <div className={`--mnu ${toogleMenu ? '--mnu--active' : ''}`}>
                    <ul className='--mnu-ul'>
                        <li className='--mnu-li'>
                            <Link to={'/products'} className={`--a-nav ${location.pathname === '/products' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Productos</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/proyects'} className={`--a-nav ${location.pathname === '/proyects' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Proyectos</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/pricing'} className={`--a-nav ${location.pathname === '/pricing' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Precios</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/partners'} className={`--a-nav ${location.pathname === '/partners' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Socio</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/about'} className={`--a-nav ${location.pathname === '/about' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Nosotros</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/blog'} className={`--a-nav ${location.pathname === '/blog' ? '--a-nav--active' : ''}`}><span className='--span-nav'>Blog</span></Link>
                        </li>
                        <li className='--mnu-li'>
                            <Link to={'/about'} className='--a-nav --a-nav--btn'><span className='--span-nav --span-nav--btn'>Contáctanos</span></Link>
                        </li>
                    </ul>
                </div>

            </div>

        </header>

    )

}

export default Header