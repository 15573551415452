import React from 'react'
import Spline from '@splinetool/react-spline';

function Error404() {

    return (

        <Spline scene='https://prod.spline.design/eJUmIx03e91TwSd9/scene.splinecode' />

    )

}

export default Error404