import React, { useEffect } from 'react'
import Header from '../layout/Header'
import Main from '../layout/Main'
import Footer from '../layout/Footer'

function HomeScreen() {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tipoSitioWeb = params.get('source_medium');
    }, []);

    return (

        <>

            <Header/>

            <Main/>

            <Footer/>

        </>

    )

}

export default HomeScreen