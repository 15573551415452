import React from 'react'

import { Helmet } from "react-helmet";

import PricingSection from '../components/sections/Pricing/PricingSection'
import CommentSection from '../components/sections/Comment/CommentSection'
import QuestionsSection from '../components/sections/Questions/QuestionsSection'

function PricingView() {

    return (

        <>

            <Helmet>
                <meta name="description" content="Consulta nuestros precios y tarifas para los servicios de desarrollo web y tecnológico. Descubre nuestras ofertas y planes de precios adaptados a tus necesidades." />
                <meta name="keywords" content="precios, tarifas, desarrollo web, tecnología, ofertas, planes de precios, precios para e-commerce, precio de sitio web, precio de landing page, precios de webs" />
                <meta name="author" content="ARCANA CORP" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Precios | ARCANA | Diseño y desarrollo web" />
                <meta property="og:description" content="Consulta nuestros precios y tarifas para los servicios de desarrollo web y tecnológico. Descubre nuestras ofertas y planes de precios adaptados a tus necesidades." />
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta property="og:url" content="https://arcanacorp.netlify.app/pricing" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Precios | ARCANA | Diseño y desarrollo web" />
                <meta name="twitter:description" content="Consulta nuestros precios y tarifas para los servicios de desarrollo web y tecnológico. Descubre nuestras ofertas y planes de precios adaptados a tus necesidades." />
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <title>Precios | ARCANA | Diseño y desarrollo web</title>
            </Helmet>
        
            <PricingSection/>

            <QuestionsSection/>

            <CommentSection/>

        </>

    )

}

export default PricingView