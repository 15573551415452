import React from 'react'
import Error404 from '../components/ui/Error404'
import { Link } from 'react-router-dom'

function ErrorScreen() {

    return (

        <div className='--content-error'>
        
            <Error404/>

            <Link to={'/'} className='--a-back'>Volver al inicio</Link>

        </div>

    )
    
}

export default ErrorScreen