import React from 'react'
import Spline from '@splinetool/react-spline'

function Cube() {

    return (

        <>
        
            <Spline scene='https://prod.spline.design/b1UUHnr3ee1XVy-t/scene.splinecode' />

        </>

    )

}

export default Cube