import React from 'react'
import { Link } from 'react-router-dom';

import Cube from "../../ui/Cube";

import './hero.css'

function HeroSection() {

    return (

        <section className='--hero'>
            
            <div className='--col-main --col-main-A'>

                <div className='--content-col'>

                    <Link to={'/'} className='--a-hero-call'>
                        <span>Introducimos MagicAI</span>
                    </Link>

                    <h1 className='--h1-title'>
                        <span className='--span --span-title'>Potencia tu</span>
                        <span className='--span --span-title'>Desarrollo</span>
                    </h1>

                    <p className='--p-text'>Potencia tu desarrollo con soluciones innovadoras y a medida de nuestra empresa de desarrollo de software.</p>

                    <Link to={'https://wa.link/3casfa'} target='_blank' className='--a-hero'>
                        <span className='--span-hero'>Comenzar</span>
                    </Link>

                </div>

            </div>

            <div className='--col-main --col-main-B'><Cube/></div>

        </section>

    )

}

export default HeroSection