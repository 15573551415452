import React from 'react'

import { stepOne, stepTwo, stepThree } from "../../../data/Steps";

import StepCard from '../../ui/card/StepCard';

import icon1 from '../../../static/img/icon icon_1.png'
import icon2 from '../../../static/img/icon icon_2.png'
import icon3 from '../../../static/img/icon icon_3.png'

import './step.css'

function StepSection() {

    return (

        <section className='--steps'>

            <div className='--step --step-one'>

                <div className='--ico'>
                    <img src={icon1} alt='En ARCANA CORP , convertimos tus ideas audaces en innovación. Nuestra creatividad y experiencia técnica dan vida a proyectos revolucionarios. | PERU | DESARROLLO WEB | DESARROLLO DE APLICACIONES' />
                </div>

                <div className='--title'>

                    <h2 className='--h2 --h2-title'>
                        <span className='--span --span-title --span-title-degrade-zer'>Innovación desde </span>
                        <span className='--span --span-title --span-title-degrade-one'>la creación</span>
                    </h2>

                    <p className='--p --p-paragraph'>
                        En <b>ARCANA CORP</b>, convertimos tus ideas audaces en innovación. Nuestra creatividad y experiencia técnica dan vida a proyectos revolucionarios.
                    </p>

                </div>

                <div className='--row-info'>

                    {stepOne.map((step, i) => (
                        <StepCard key={i} step={step} />
                    ))}

                </div>

            </div>

            <div className='--step --step-two'>

                <div className='--ico'>
                    <img src={icon2} alt='ARCANA CORP | Personalizamos soluciones para satisfacer tus necesidades únicas, eliminando la fricción y optimizando la eficiencia de tu negocio. | PERU | DESARROLLO WEB | DESARROLLO DE APLICACIONES' />
                </div>

                <div className='--title'>

                    <h2 className='--h2 --h2-title'>
                        <span className='--span --span-title --span-title-degrade-zer'>Hacemos que </span>
                        <span className='--span --span-title --span-title-degrade-two'>funcione para ti</span>
                    </h2>

                    <p className='--p --p-paragraph'>
                        Personalizamos soluciones para satisfacer tus necesidades únicas, eliminando la fricción y optimizando la eficiencia de tu negocio.
                    </p>

                </div>

                <div className='--row-info'>

                    {stepTwo.map((step, i) => (
                        <StepCard key={i} step={step} />
                    ))}

                </div>

            </div>

            <div className='--step --step-three'>

                <div className='--ico'>
                    <img src={icon3} alt='ARCANA CORP | Cada proyecto que abordamos se convierte en un logro compartido. Nos esforzamos por superar tus expectativas y entregar resultados de calidad. | PERU | DESARROLLO WEB | DESARROLLO DE APLICACIONES' />
                </div>

                <div className='--title'>

                    <h2 className='--h2 --h2-title'>
                        <span className='--span --span-title --span-title-degrade-zer'>Tus proyectos, </span>
                        <span className='--span --span-title --span-title-degrade-three'>nuestros logros</span>
                    </h2>

                    <p className='--p --p-paragraph'>
                        Cada proyecto que abordamos se convierte en un logro compartido. Nos esforzamos por superar tus expectativas y entregar resultados de calidad.
                    </p>

                </div>

                <div className='--row-info'>

                    {stepThree.map((step, i) => (
                        <StepCard key={i} step={step} />
                    ))}

                </div>

            </div>

        </section>

    )

}

export default StepSection