import React, { useState, useEffect, useRef } from 'react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import categoriasProyectos from "../../../data/Categories";

import './styles/proyects.css'
import ProyectCard from '../../ui/card/ProyectCard';

function ProyectSection() {

    const [ category, setCategory ] = useState('all');
    const [ scrollPosition, setScrollPosition ] = useState(0);
    const scrollRef = useRef(null);

    const handleCategoryClick = (key) => {
        setCategory(key);
        window.history.pushState({}, '', `?type_website=${key}`);
    };

    const handleScroll = (direction) => {
        const container = scrollRef.current;
        if (container) {
            const increment = direction === 'left' ? -100 : 100;
            container.scrollBy({ left: increment, behavior: 'smooth' });
            setScrollPosition(container.scrollLeft + increment);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tipoSitioWeb = params.get('type_website');
        setCategory(tipoSitioWeb !== null ? tipoSitioWeb : 'all');
    }, []);

    return (

        <div className='--proyects'>

            <div className='--title'>
                <h1>Proyectos</h1>
                <p>Aquí puedes encontrar información sobre los proyectos en los que estamos trabajando actualmente.</p>
            </div>

            <div className={`--ctgs --scrolling-${scrollPosition}`}>
                <div className='--ctgs-content-lst' >
                    <button className='--btn-scroll --btn-scroll-left' onClick={() => handleScroll('left')}><IconChevronLeft/></button>
                    <ul className='--ctgs-list' ref={scrollRef}>
                        <li className={`--ctgs-item ${category === 'all' ? '--ctgs-item--active' : ''}`} onClick={() => handleCategoryClick('all')}>
                            <span>Todo</span>
                        </li>
                        {Object.entries(categoriasProyectos).map(([key, value]) => (
                            <li key={key} className={`--ctgs-item ${category === key ? '--ctgs-item--active' : ''}`} onClick={() => handleCategoryClick(key)}>
                                <span>{value}</span>
                            </li>
                        ))}
                    </ul>
                    <button className='--btn-scroll --btn-scroll-right' onClick={() => handleScroll('right')}><IconChevronRight/></button>
                </div>
            </div>


            <div className='--grd-proyects'>
                <ProyectCard/>
                <ProyectCard/>
                <ProyectCard/>
                <ProyectCard/>
                <ProyectCard/>
                <ProyectCard/>
            </div>

        </div>

    )

}

export default ProyectSection