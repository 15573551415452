import React from 'react'

import './styles/productcard.css'
import { Link } from 'react-router-dom'

function ProductCard() {

    return (

        <article className='--card-product'>

            <div className='--line'></div>

            <Link to={'/'} target='_blank'>Ver más</Link>

        </article>

    )

}

export default ProductCard