import React from 'react'
import { Link } from 'react-router-dom'
import { IconBrandFacebook, IconBrandInstagram, IconBrandX } from '@tabler/icons-react'

import './styles/footer.css'

function Footer() {

    return (

        <footer className='--footer'>

            <div className='--line'></div>

            <div className='--content-footer'>

                <div className='--col-F --col-F-A'>

                    <div className='--content-red'>
                        <p>Jauja - Junín - Perú</p>
                        <p>+51 966 327 426</p>
                        <p>RUC: <b>20612123901</b></p>

                        <ul className='--reds'>
                            <li className='--red'>
                                <Link to={'https://www.facebook.com/arcanacorp12'} target='_blank'>
                                    <IconBrandFacebook/>
                                </Link>
                            </li>
                            <li className='--red'>
                                <Link to={'https://www.instagram.com/arcanacorp12/'} target='_blank'>
                                    <IconBrandInstagram/>
                                </Link>
                            </li>
                            <li className='--red'>
                                <Link to={'https://twitter.com/ArcanaCorp12'} target='_blank'>
                                    <IconBrandX/>
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className='--col-F --col-F-B'>

                    <ul>
                        <h4>Recursos</h4>
                        <li className=''>
                            <Link to={'/proyects'}>Proyectos</Link>
                        </li>
                        <li className=''>
                            <Link to={'/products'}>Productos</Link>
                        </li>
                        <li className=''>
                            <Link to={'/pricing'}>Precios</Link>
                        </li>
                    </ul>

                    <ul>
                        <h4>Compañia</h4>
                        <li className=''>
                            <Link to={'/blog'}>Blog</Link>
                        </li>
                        <li className=''>
                            <Link to={'/about'}>Nosotros</Link>
                        </li>
                        <li className=''>
                            <Link to={'/partners'}>Socios</Link>
                        </li>
                    </ul>

                    <ul>
                        <h4>Legal</h4>
                        <li className=''>
                            <Link to={'/'}>Reclamos</Link>
                        </li>
                        <li className=''>
                            <Link to={'/'}>Términos de Servicio</Link>
                        </li>
                    </ul>

                </div>

            </div>

        </footer>

    )

}

export default Footer