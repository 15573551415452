import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UIProvider } from "./context/UIContext";

import HomeScreen from './screens/HomeScreen';
import ErrorScreen from './screens/ErrorScreen';

import './static/css/global.css'
import ProductScreen from './screens/ProductScreen';
import ProyectScreen from './screens/ProyectScreen';
import PricingScreen from './screens/PricingScreen';
import PartnerScreen from './screens/PartnerScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';
import BlogScreen from './screens/BlogScreen';

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomeScreen/>,
        children: [
            {
                path: '/products',
                element: <ProductScreen/>
            },
            {
                path: '/proyects',
                element: <ProyectScreen/>
            },
            {
                path: '/pricing',
                element: <PricingScreen/>
            },
            {
                path: '/partners',
                element: <PartnerScreen/>
            },
            {
                path: '/about',
                element: <AboutScreen/>
            },
            {
                path: '/contact',
                element: <ContactScreen/>
            },
            {
                path: '/blog',
                element: <BlogScreen/>,
                children: [
                    {
                        path: '/blog/:idPost',
                        element: <BlogScreen/>
                    }
                ]
            }
        ],
        errorElement: <ErrorScreen/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <UIProvider>

        <RouterProvider router={router} />
        
    </UIProvider>

);