import React from 'react'

import logo from '../../../static/img/ICONOS@1-1536x730.png'

import './styles/about.css'

function AboutSection() {

    return (

        <>

            <section className='--about'>

                <div className='--figure-logo'>

                    <div className='--lgo'>
                        <img src={logo} alt='Logo de ARCANA CORP' />
                    </div>

                    <div className='--tti-lg'>
                        <h1>
                            <span>Transformamos ideas</span>
                            <span>en realidad digital</span>
                        </h1>
                        <p>Transformamos ideas en experiencias digitales. Nuestra misión es impulsar el éxito de tu negocio a través de soluciones tecnológicas innovadoras y creativas.</p>
                    </div>

                </div>

            </section>

            <section className='--ourabout'>

                <div className='--line'></div>

                <div className='--tti'>
                    <h1>Nuestra historia</h1>
                </div>

                <div className='--prr'>
                    <p>
                        <b>Arcana</b> se fundó en febrero de 2024 con el propósito de resolver 
                        desafíos tecnológicos. Inicialmente, nos enfrentamos a la 
                        dificultad de crear soluciones que funcionaran bien en todos los 
                        entornos.
                    </p>
                    <p>
                        Con el tiempo, nos dimos cuenta de que nuestro mayor desafío era asegurar 
                        la calidad y eficacia de nuestras soluciones. En 2025, lanzamos una gama 
                        completa de servicios que incluyen desarrollo web, diseño gráfico y soluciones a medida.
                    </p>
                    <p>
                        Nuestro objetivo es ser líderes en la industria, ofreciendo tecnología 
                        innovadora y servicios excepcionales que ayuden a nuestros clientes a 
                        alcanzar el éxito en el mundo digital.
                    </p>
                </div>

            </section>
        
        </>

    )

}

export default AboutSection