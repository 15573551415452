import React from 'react'
import { Helmet } from 'react-helmet'

import ProductSection from '../components/sections/Products/ProductSection'

function ProductsView() {

    return (

        <>
        
            <Helmet>
                <meta name="description" content="Explora nuestra amplia gama de productos y servicios. Desde desarrollo web hasta soluciones de inteligencia artificial, tenemos lo que necesitas para impulsar tu negocio al siguiente nivel." />
                <meta name="keywords" content="productos, servicios, desarrollo web, inteligencia artificial, tecnología, innovación, negocios, buscar trabajo, diseño gráfico" />
                <meta name="author" content="ARCANA CORP" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Productos | ARCANA | Diseño y desarrollo web" />
                <meta property="og:description" content="Explora nuestra amplia gama de productos y servicios. Desde desarrollo web hasta soluciones de inteligencia artificial, tenemos lo que necesitas para impulsar tu negocio al siguiente nivel." />
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta property="og:url" content="https://arcanacorp.netlify.app/products" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Productos | ARCANA | Diseño y desarrollo web" />
                <meta name="twitter:description" content="Explora nuestra amplia gama de productos y servicios. Desde desarrollo web hasta soluciones de inteligencia artificial, tenemos lo que necesitas para impulsar tu negocio al siguiente nivel." />
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <title>Productos | ARCANA | Diseño y desarrollo web</title>
            </Helmet>

            <ProductSection/>

        </>

    )

}

export default ProductsView