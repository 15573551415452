import React from 'react'

import logotipo from '../../../static/img/icono-white.svg'

import './me.css'

function Me() {

    return (

        <section className='--section-me'>


            <div className='--line'></div>


            <div className='--logo-me'>
                <div className='--lopp'>
                    <img src={logotipo} alt='Logo de ARCANA CORP | Desarrolldor Web | Desarrollo de Aplicaciones | Perú' />
                </div>
            </div>

            <p className='--phrase'>
                ARCANA potenciara tu presencia en línea. Brindamos una atención personalizada, apoyo en todo momento, asesoria constante. Que más se puede pedir.
            </p>

            <div className='--ceo-content'>
                <div className='--avatar'></div>
                <div className='--ceo'>
                    <h4>Franco Pérez</h4>
                    <p>CEO de ARCANA</p>
                </div>
            </div>


        </section>

    )

}

export default Me