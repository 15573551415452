import React from 'react'
import { useLocation } from 'react-router-dom'

import HomeView from '../views/HomeView';
import ProductsView from '../views/ProductsView'
import ProyectsView from '../views/ProyectsView';
import PricingView from '../views/PricingView';
import PartnersView from '../views/PartnersView';
import AboutView from '../views/AboutView';
import BlogView from '../views/BlogView';
import BlogPostView from '../views/BlogPostView';

import './styles/main.css'

function Main() {

    const location = useLocation();

    return (

        <>
        
            <main className='--main'>

                <div className='--content'>

                    {location.pathname === '/' && ( <HomeView/> )}

                    {location.pathname === '/products' && ( <ProductsView/> )}

                    {location.pathname === '/proyects' && ( <ProyectsView/> )}

                    {location.pathname === '/pricing' && ( <PricingView/> )}

                    {location.pathname === '/partners' && ( <PartnersView/> )}
                    
                    {location.pathname === '/about' && ( <AboutView/> )}

                    {location.pathname === '/blog' && ( <BlogView/> )}

                    {location.pathname.startsWith('/blog/') && ( <BlogPostView/> )}

                </div>

            </main>

        </>

    )

}

export default Main