import React from 'react'
import preguntasFrecuentes from "../../../data/AskedQuestions";

import Question from '../../ui/card/Question';

import './questions.css'

function QuestionsSection() {

    return (

        <section className='--questions'>

            <div className='--title'>
                <h1>Preguntas Frecuentes</h1>
                <p>¿Tienes dudas sobre nuestras tarifas? ¡Estamos aquí para ayudarte! Ofrecemos tarifas personalizadas para landing pages, sitios web, e-commerce y desarrollos a medida.</p>
            </div>

            <div className='--lst-questions'>
                {preguntasFrecuentes.map((question, i) => (
                    <Question key={i} question={question} />
                ))}
            </div>

        </section>

    )

}

export default QuestionsSection