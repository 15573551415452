import React from 'react'

import { Helmet } from "react-helmet";

import HeroSection from '../components/sections/Hero/HeroSection'
import StepSection from '../components/sections/Step/StepSection'
import Me from '../components/sections/Me/Me'
import CommentSection from '../components/sections/Comment/CommentSection'

function HomeView() {

    return (

        <>

            <Helmet>

                <meta name="description" content="ARCANA tiene la visión de ser global y con visión futurista. Nuestra misión es facilitar a las empresas su transición a un mundo tecnológico, aportando nuestro toque VIP."/>
                <meta name="keywords" content="desarrollo web, empresa, visión global, visión futurista, tecnología, toque VIP, desarrollo web perú, aplicaciones web perú, perú, jauja, huancayo, lima, latinoamerica"/>
                <meta name="author" content="ARCANA CORP"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:title" content="ARCANA | Diseño y desarrollo web"/>
                <meta property="og:description" content="ARCANA tiene la visión de ser global y con visión futurista. Nuestra misión es facilitar a las empresas su transición a un mundo tecnológico, aportando nuestro toque VIP."/>
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png"/>
                <meta property="og:url" content="https://arcanacorp.netlify.app/"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="ARCANA | Diseño y desarrollo web"/>
                <meta name="twitter:description" content="ARCANA tiene la visión de ser global y con visión futurista. Nuestra misión es facilitar a las empresas su transición a un mundo tecnológico, aportando nuestro toque VIP."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <title>ARCANA | Diseño y desarrollo web</title>

            </Helmet>
        
            <HeroSection/>

            <StepSection/>

            <Me/>

            <CommentSection/>

        </>

    )

}

export default HomeView