import React, { useState, useEffect, createContext } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ isScrollingDown, setIsScrollingDown ] = useState(false);

    useEffect(() => {

        const handleScroll = () => {

            const scrollPosition = window.scrollY;
            setIsScrollingDown(scrollPosition === 0 ? false : true);
            
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup: remove event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const contextValue = {
        isScrollingDown
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export default UIContext;