import React from 'react'
import { Link } from 'react-router-dom'

import './styles/proyectcard.css'

function ProyectCard() {

    return (

        <article className='--card-proyects'>

            <div className='--line'></div>

            <Link to={'/'} target='_blank'>Ver más</Link>

        </article>

    )

}

export default ProyectCard