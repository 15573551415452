const categoriasProyectos = {
    restaurants: 'Restaurantes',
    portfolios: 'Portafolios',
    ecommerce: 'Ecommerce',
    constructors: 'Constructoras',
    education: 'Educación',
    health: 'Salud',
    travel: 'Viajes',
    entertainment: 'Entretenimiento',
    technology: 'Tecnología',
    fashion: 'Moda'
};

export default categoriasProyectos;