import React from 'react'
import './blogpost.css'
import { useParams } from 'react-router-dom'

function BlogPostSection() {

    const { idPost } = useParams();

    console.log(idPost);

    return (

        <section className='--blog-post'>

            <div className='--title'>
                <span>Mar. 18, 2024</span>
                <h1>{idPost}</h1>
                <p>Ofrecemos tarifas personalizadas para landing pages, sitios web, e-commerce y desarrollos a medida.</p>
                <div className='--author'>
                    <figure className='--avatar-author'></figure>
                    <h4>Joe Dancel</h4>
                </div>
            </div>

        </section>

    )

}

export default BlogPostSection