const preguntasFrecuentes = [
    {
        pregunta: "¿Cuáles son los métodos de pago aceptados?",
        respuesta: "Aceptamos pagos con tarjeta de crédito/débito (Visa, Mastercard, etc.) y transferencia bancaria."
    },
    {
        pregunta: "¿Cuánto tiempo tarda en completarse un proyecto?",
        respuesta: "El tiempo de entrega varía según el tipo de proyecto, pero trabajamos para completarlos lo más rápido posible sin sacrificar calidad. Normalmente, una landing page tarda una semana, un sitio web alrededor de dos semanas y un e-commerce unas tres semanas."
    },
    {
        pregunta: "¿Ofrecen soporte técnico después de finalizar el proyecto?",
        respuesta: "Sí, ofrecemos soporte técnico gratuito durante un mes después de la finalización del proyecto para ayudarte con cualquier problema o consulta."
    },
    {
        pregunta: "¿Qué información necesitan para comenzar un proyecto?",
        respuesta: "Necesitamos información básica sobre tu empresa, como la visión y misión, los servicios/productos que ofreces, y cualquier contenido específico que quieras incluir en el proyecto."
    },
    {
        pregunta: "¿Puedo actualizar mi sitio web o e-commerce yo mismo?",
        respuesta: "Sí, proporcionamos un panel de administración fácil de usar donde puedes actualizar fácilmente el contenido, agregar nuevos productos y administrar pedidos."
    },
    {
        pregunta: "¿Qué sucede si no estoy satisfecho con el resultado final?",
        respuesta: "Nuestro objetivo es garantizar tu satisfacción. Si no estás satisfecho con el resultado final, trabajaremos contigo para realizar las revisiones necesarias hasta que estés contento."
    },
    {
        pregunta: "¿Ofrecen servicios de marketing digital?",
        respuesta: "Sí, ofrecemos servicios de marketing digital como SEO (optimización en motores de búsqueda), campañas de publicidad en redes sociales y estrategias de marketing de contenidos para ayudarte a aumentar la visibilidad de tu negocio en línea."
    }
];

export default preguntasFrecuentes;