import React from 'react'
import BlogPostSection from '../components/sections/BlogPost/BlogPostSection'

function BlogPostView() {

    return (

        <>
        
            <BlogPostSection/>

        </>

    )

}

export default BlogPostView