import React from 'react'
import { Helmet } from 'react-helmet'

import ProyectSection from '../components/sections/Proyects/ProyectSection'

function ProyectsView() {

    return (

        <>

            <Helmet>
                <meta name="description" content="Explora nuestros proyectos y casos de estudio. Descubre cómo hemos ayudado a diversas empresas a alcanzar sus objetivos con soluciones tecnológicas innovadoras." />
                <meta name="keywords" content="proyectos, casos de estudio, soluciones tecnológicas, innovación, éxito empresarial, paginas web, sitios web" />
                <meta name="author" content="ARCANA CORP" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Proyectos | ARCANA | Diseño y desarrollo web" />
                <meta property="og:description" content="Explora nuestros proyectos y casos de estudio. Descubre cómo hemos ayudado a diversas empresas a alcanzar sus objetivos con soluciones tecnológicas innovadoras." />
                <meta property="og:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta property="og:url" content="https://arcanacorp.netlify.app/proyects" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Proyectos | ARCANA | Diseño y desarrollo web" />
                <meta name="twitter:description" content="Explora nuestros proyectos y casos de estudio. Descubre cómo hemos ayudado a diversas empresas a alcanzar sus objetivos con soluciones tecnológicas innovadoras." />
                <meta name="twitter:image" content="%PUBLIC_URL%/PORTADA.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <title>Proyectos | ARCANA | Diseño y desarrollo web</title>
            </Helmet>
        
            <ProyectSection/>

        </>

    )

}

export default ProyectsView