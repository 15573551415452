import React from 'react'
import { pricingLst } from "../../../data/Pricing";

import './styles/pricing.css'
import PriceCard from '../../ui/card/PriceCard';

function PricingSection() {

    return (

        <section className='--pricing'>

            <div className='--title'>
                <h1>Precios</h1>
                <p>Ofrecemos tarifas personalizadas para landing pages, sitios web, e-commerce y desarrollos a medida.</p>
            </div>

            <div className='--grd-prices'>

                {pricingLst.map((price, i) => (
                    <PriceCard key={i} price={price} />
                ))}

            </div>

        </section>

    )

}

export default PricingSection