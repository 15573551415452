import React from 'react'
import { Link } from 'react-router-dom'
import slugify from 'slugify';

import './styles/blogcard.css'

function BlogCard({ type, title, author, date }) {

    const slug = typeof title === 'string' ? slugify(title, { lower: true, remove: /[*+~.()'"!:@]/g }) : '';


    return (

        <article className={`--blog-card ${type}`}>

            <Link to={`/blog/${slug}`} className='--a-blog-card'>

                <div className='--blog-card-img'></div>

                <div className='--blog-card-txt'>
                    <h4>{title}</h4>
                    <div className='--row'>
                        <figure className='--avatar'></figure>
                        <p className='--data-p'><span className='--data-p-name'>{author}</span> · <span className='--data-p-date'>{date}</span></p>
                    </div>
                </div>

            </Link>

        </article>

    )

}

export default BlogCard