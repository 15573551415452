import React from 'react'
import { blogPosts } from "../../../data/Blog";

import BlogCard from '../../ui/card/BlogCard'

import './blog.css'

function BlogSection() {

    return (

        <section className='--blog'>

            <div className='--title'>
                <h1>Blog</h1>
                <p>Ofrecemos tarifas personalizadas para landing pages, sitios web, e-commerce y desarrollos a medida.</p>
            </div>

            <div className='--new-grd-news'>
                <BlogCard type={'new'}/>
                <BlogCard type={'new'} />
            </div>

            <h3>Articulos anteriores</h3>

            <div className='--lst-grd-news'>
                {blogPosts.map((blog, i) => (
                    <BlogCard key={i} type={'lst'} title={blog.title} author={blog.author} date={blog.date} />
                ))}
            </div>

        </section>

    )

}

export default BlogSection