import React from 'react'
import { Link } from 'react-router-dom';
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

function PriceCard({ price }) {
    return (
        <div className='--card-price'>

            <div className='--line'></div>

            <h3 className='--h3-tt-card'><span>{price.service}</span></h3>
            <span className='--span-tt-card'>
                <span className='--span-block'>{price.subtxt === 'soles' ? 's/.' : ''} <span className='--span-price-lg'>{price.price}</span></span>
                <span className='--span-block'>{price.subtxt} x mes</span>
            </span>
            <div className='--line-card'></div>
            <ul className='--list-offer'>
                {price.items.map((item, i) => (
                    <li className='--item-offer' key={i}>
                        <span className='--ico-offer'>{item.available ? <IconCircleCheck/> : <IconCircleX/>}</span>
                        <span className='--txt-offer'>{item.item} <span className='--txt-offer-small'>{item.smallTxt ? '- 1 año' : ''} {item.item === 'Dominio' || item.item === 'Hosting' ? 'gratis' : ''}</span></span>
                    </li>
                ))}
            </ul>
            <Link to={price.link} target='_blank' className='--btn-offer'>Comenzar</Link>

        </div>
    )
}

export default PriceCard