export const blogPosts = [
    {
        title: "Introducción a la ingeniería de software",
        date: "2024-05-01",
        image: "url_de_la_imagen_1",
        author: "Juan Pérez"
    },
    {
        title: "Principios básicos de diseño UI/UX",
        date: "2024-05-03",
        image: "url_de_la_imagen_2",
        author: "María Gutiérrez"
    },
    {
        title: "Estrategias para el desarrollo ágil",
        date: "2024-04-28",
        image: "url_de_la_imagen_3",
        author: "Pedro Martínez"
    },
    {
        title: "Herramientas para la gestión de proyectos",
        date: "2024-04-25",
        image: "url_de_la_imagen_4",
        author: "Ana López"
    },
    {
        title: "Nuevas tendencias en desarrollo de aplicaciones móviles",
        date: "2024-04-20",
        image: "url_de_la_imagen_5",
        author: "Carlos Rodríguez"
    },
    {
        title: "Seguridad informática en el desarrollo de software",
        date: "2024-04-15",
        image: "url_de_la_imagen_6",
        author: "Laura Sánchez"
    },
    {
        title: "Optimización de rendimiento en aplicaciones web",
        date: "2024-04-10",
        image: "url_de_la_imagen_7",
        author: "David García"
    },
    {
        title: "Machine Learning aplicado al diseño",
        date: "2024-04-05",
        image: "url_de_la_imagen_8",
        author: "Elena Fernández"
    },
    {
        title: "Integración continua y entrega continua (CI/CD)",
        date: "2024-04-01",
        image: "url_de_la_imagen_9",
        author: "Andrés Ruiz"
    },
    {
        title: "El papel de la inteligencia artificial en el desarrollo de software",
        date: "2024-03-30",
        image: "url_de_la_imagen_10",
        author: "Sofía Martín"
    }
];