import React, { useState } from 'react'
import { IconChevronRight, IconChevronDown } from '@tabler/icons-react';

function Question({ question }) {

    const [ toogleQuestion, setToogleQuestion ] = useState(false);

    return (

        <article className={`--item-question ${toogleQuestion ? '--item-question--open' : ''}`}>

            <div className='--row-question'>
                <span className='--ico-question' onClick={() => setToogleQuestion(!toogleQuestion)}>{toogleQuestion ? <IconChevronDown/> : <IconChevronRight/>}</span>
                <h4>{question.pregunta}</h4>
            </div>
            <div className='--row-response'>
                <span className='--ico-question'></span>
                <p>{question.respuesta}</p>
            </div>

        </article>

    )

}

export default Question