import React from 'react'

import './styles/stepcard.css'

function StepCard({ step }) {

    return (

        <article className='--col --col-info'>

            <div className='--figure'>
                <div className='--line'></div>
            </div>

            <div className='--head'>
                <div className='--icn'>{step.icon}</div>
                <h3 aria-label={step.title}>{step.title}</h3>
            </div>

            <div className='--bodt'><p aria-label={step.text}>{step.text}</p></div>

        </article>

    )

}

export default StepCard